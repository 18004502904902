// Generated by Framer (26d4882)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["tEkOj9bF9", "DrqpbPCvN"];

const serializationHash = "framer-mfsKg"

const variantClassNames = {DrqpbPCvN: "framer-v-ap5dzs", tEkOj9bF9: "framer-v-1fk10p2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {duration: 0, type: "tween"}, DrqpbPCvN: {delay: 0, duration: 2, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Invisable: "DrqpbPCvN", Visable: "tEkOj9bF9"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "tEkOj9bF9"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "tEkOj9bF9", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearf382t5 = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 2000)
})

useOnVariantChange(baseVariant, {default: onAppearf382t5})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-mfsKg", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1fk10p2", className)} data-framer-name={"Visable"} data-highlight layoutDependency={layoutDependency} layoutId={"tEkOj9bF9"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({DrqpbPCvN: {"data-framer-name": "Invisable"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1qmovma"} layoutDependency={layoutDependency} layoutId={"Ejd_nL4Bb"} style={{backgroundColor: "var(--token-9df8373e-36c8-429a-9d37-e2fe6c12d523, rgb(255, 47, 0))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, opacity: 1}} variants={{DrqpbPCvN: {opacity: 0}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-mfsKg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mfsKg .framer-71jnnj { display: block; }", ".framer-mfsKg .framer-1fk10p2 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 4px; justify-content: center; overflow: hidden; padding: 1px 1px 1px 1px; position: relative; width: 4px; }", ".framer-mfsKg .framer-1qmovma { flex: none; height: 4px; overflow: hidden; position: relative; width: 4px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mfsKg .framer-1fk10p2 { gap: 0px; } .framer-mfsKg .framer-1fk10p2 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-mfsKg .framer-1fk10p2 > :first-child { margin-left: 0px; } .framer-mfsKg .framer-1fk10p2 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 4
 * @framerIntrinsicWidth 4
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"DrqpbPCvN":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerwHP_vNjpw: React.ComponentType<Props> = withCSS(Component, css, "framer-mfsKg") as typeof Component;
export default FramerwHP_vNjpw;

FramerwHP_vNjpw.displayName = "Orange Dot";

FramerwHP_vNjpw.defaultProps = {height: 4, width: 4};

addPropertyControls(FramerwHP_vNjpw, {variant: {options: ["tEkOj9bF9", "DrqpbPCvN"], optionTitles: ["Visable", "Invisable"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerwHP_vNjpw, [])